import React from 'react';
import BaseTemplate from '../../../components/templates/BaseLayout';
import SEO from '../../../components/atoms/utility/seo';
import Spacer from '../../../components/atoms/utility/Spacer';
import EventsListBlogV2 from '../../../components/organisms/EventsListBlogV2';
import { austinEvents } from '../../../content/events/austin-events';

function AustinLiveMusic() {
  return (
    <BaseTemplate>
      <SEO
        title={'Live Music Austin'}
        description={'Come find Live Music in Austin!'}
      />
      <Spacer sizeY={1} className="" />
      <EventsListBlogV2
        cityName="Austin"
        categoryPage={'Live Music'}
        eventsData={austinEvents}
      />
      <Spacer sizeY={1} className="" />
    </BaseTemplate>
  );
}

export default AustinLiveMusic;
